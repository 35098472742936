<template>
    <payment-common>
    <template #header>
      <b-alert variant="primary" v-if="processing" show>Submitting card payment</b-alert>
      <b-alert variant="warning" v-if="error" show dismissible>{{ error.message }}</b-alert>
      <b-alert variant="success" v-if="internalValue.finalised" show dismissible>Payment successfully processed</b-alert>
    </template>
    <reg-form-input label="Amount Paid Today" prepend="£" min="0" max="15000"
        :formatter="moneyNumberOnly"
        :value="moneyNumberOnly(depositAmount)"
        @change="depositAmount = Number($event)"
        :disabled="true"
        :$v="$v.depositAmountInCents"
          lazy-formatter field-name="Deposit"
         :messages="{
           minValue: `Please enter a value greater than ${$i18n.n(internalValue.amount * 0.25, 'currency')}`,
           maxValue: `Maximum amount allowed is ${$i18n.n(internalValue.amount - 3, 'currency')}`
         }"
    />
    <reg-form-radio v-if="termsList.length > 1" :disabled="!canEdit" v-model="internalValue.instalments" :$v="$v.instalments" label="Instalments" field-name="Term" :options="termsList" />
    <b-form-row v-if="payments" class="mb-3">
      <b-col md="8" offset-md="4">
        <b-card title="Instalments">
          <b-table v-if="payments" :items="payments" :fields="paymentFields" striped responsive small />
        </b-card>
      </b-col>
    </b-form-row>
    <stripe-elements v-if="!internalValue.paymentMethod" />
    <reg-form-input v-if="internalValue.finalised && internalValue.receiptNumber" plaintext label="Receipt Number" :value="internalValue.receiptNumber" />
    <template #footer v-if="!internalValue.finalised">
      <spinner class="mt-3" v-if="processing" color="rgb(24, 108, 149)" />
      <b-button class="float-right ml-3" variant="primary" @mouseover="$v.paidBy.$touch" @click="setupPaymentPlan" :disabled="!canSubmit || !cardDetailsOk">Process payment now for {{ $n(depositAmount, 'currency') }}</b-button>
    </template>
  </payment-common>
</template>

<script lang="ts">
import stripeMixin from './stripeElementsMixin'
import { StripePaymentPlan, InstalmentType } from '@iris/store/payments/types'
import PaymentsApi from '@iris/store/payments/api'
import { IrisStore, IrisGetters } from '../../store/types'
import spinner from 'vue-spinner/src/RiseLoader.vue'
import * as Sentry from '@sentry/browser'
import { BvTableFieldArray } from 'bootstrap-vue/esm'
import { DeferredPaymentApi } from '../../store/payments/deferredApi'
import { Id } from '@feathersjs/feathers'
import type { StripeElementChangeEvent } from '@stripe/stripe-js'

export default stripeMixin<StripePaymentPlan>().extend({
  inject: ['$feathers'],
  components: {
    spinner
  },
  computed: {
    canSubmit (): boolean {
      return !(this.internalValue.finalised || this.processing)
    },
    canEdit (): boolean {
      return !(this.internalValue.paymentMethod || this.processing)
    },
    paymentsApi (): PaymentsApi {
      return new PaymentsApi(this.$store as IrisStore, this.$feathers!)
    },
    deferredPaymentsApi (): DeferredPaymentApi {
      return new DeferredPaymentApi(this.$store as IrisStore, this.$feathers!)
    },
    depositAmount: {
      get ():number {
        return this.internalValue.depositAmountInCents / 100
      },
      set (value: number) {
        this.$set(this.internalValue, 'depositAmountInCents', Math.floor(value * 100))
      }
    },
    cardDetailsOk (): boolean {
      if (!this.internalValue.paymentMethod) {
        return ([this.number, this.expiry, this.cvc] as StripeElementChangeEvent[]).every(field => field.complete) &&
          // ignore validations on finalised
          Object.keys(this.$v).filter(k => k[0] !== '$' && k !== 'finalised').every(key => !this.$v[key].$invalid)
      }
      return true
    },
    termsList (): { text: string, value: 1 | 2 |3 }[] {
      return [
        // { text: 'Two', value: 1 },
        { text: 'Three', value: 2 }
      ]
    },
    balanceInCents (): number {
      return this.internalValue.amountInCents - this.internalValue.depositAmountInCents
    },
    paymentFields (): BvTableFieldArray {
      return [
        {
          key: 'monthNumber',
          label: 'Payment'
        },
        {
          key: 'date'
        },
        {
          key: 'amountInCents',
          label: 'Amount',
          formatter: v => this.$i18n.n(v / 100, 'currency')
        }
      ]
    },
    payments (): InstalmentType[] | undefined {
      if (this.internalValue.instalments) {
        const payments: InstalmentType[] = []
        let remainingAmount = this.balanceInCents
        for (let i = 0; i < this.internalValue.instalments; i++) {
          const amountInCents = i === this.internalValue.instalments - 1 ? remainingAmount : Math.ceil(this.balanceInCents / this.internalValue.instalments)
          remainingAmount -= amountInCents
          payments.push({
            date: (this.$store.getters as IrisGetters).moment().add(i + 1, 'month').format('YYYY-MM-DD'),
            amountInCents,
            monthNumber: i + 1
          })
        }
        return payments
      }
    }
  },
  watch: {
    'internalValue.amountInCents': {
      handler () { this.setupAmountPaidToday() },
      immediate: true
    },
    'internalValue.instalments': {
      handler () { this.setupAmountPaidToday() },
      immediate: true
    },
    payments (schedule: InstalmentType[] | undefined) {
      this.$set(this.internalValue, 'instalmentsSchedule', schedule)
    },
    termsList: {
      immediate: true,
      handler () {
        if (this.termsList.length === 1 && !this.internalValue.instalments) {
          this.internalValue = {
            ...this.internalValue,
            instalments: this.termsList[0].value
          }
        }
      }
    }
  },
  mounted () {
    // setup the months instalments upon mount
    const regexp = /-(\d+)$/
    const monthsCode = (this.$store as IrisStore).state.promoCodes.find((c) => regexp.test(c))
    if (monthsCode) {
      this.$set(this.internalValue, 'instalments', Number(regexp.exec(monthsCode)![1]) - 1)
    }
  },
  methods: {
    setupAmountPaidToday () {
      if (this.internalValue.instalments) {
        this.$set(this.internalValue, 'depositAmountInCents', Math.floor(this.internalValue.amountInCents / (this.internalValue.instalments + 1)))
      }
    },
    async setupPaymentPlan () {
      this.processing = true
      this.error = null
      try {
        if (!this.internalValue.paymentMethod) {
          // 1st need to create customer record
          const subscription = await (this.$store.dispatch('payments/setSubscription') as Promise<Id>)
          // we need a payment intent with the amount set, so either create it or update it again
          this.internalValue = await this.paymentsApi.createOrPatch(this.internalValue, {
            subscription,
            amountInCents: this.internalValue.depositAmountInCents // TODO make sure validations are ok
          })
          let result = await this.$stripe!.confirmCardPayment(this.internalValue.clientSecret!, {
            save_payment_method: true,
            payment_method: {
              card: this.$cardNumber!,
              billing_details: {
                address: {
                  city: this.payerDetails.city,
                  country: this.payerDetails.country,
                  line1: this.payerDetails.address1,
                  line2: this.payerDetails.address2,
                  postal_code: this.payerDetails.postcode
                },
                email: this.payerDetails.email,
                name: `${this.payerDetails.title} ${this.payerDetails.firstName} ${this.payerDetails.lastName}`
              }
            }
          })
          // TODO don't do payment here
          this.$set(this.internalValue, 'debugResponse', result) // save the result into store for debugging purposes
          if (result.error) {
            this.error = result.error
          } else if (result.paymentIntent) {
            this.$set(this.internalValue, 'intentStatus', result.paymentIntent.status)
            if (this.internalValue.intentStatus === 'succeeded') {
              this.$set(this.internalValue, 'paymentMethod', result.paymentIntent.payment_method)
            } else {
              this.error = { message: `No error returned but payment is not successful, Payment Status: ${this.internalValue.intentStatus}` }
            }
          }
        }
        if (this.internalValue.paymentMethod && this.internalValue.instalmentsSchedule) {
          // TODO what happens if this fails? we have taken money but not completed everything
          this.internalValue = await this.deferredPaymentsApi.createPaymentPlanFuturePayments(this.internalValue)
          this.internalValue.finalised = true
        }
      } catch (error) {
        Sentry.captureException(error)
        this.error = error
      }
      this.processing = false
      // setup payment plan
      // this.$stripe!.handleCardPayment()
    }
  }
})
</script>
