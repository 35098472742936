<template>
  <payment-common />
</template>

<script lang="ts">

import paymentMixins from './mixin'
import { ChequePayment } from '@iris/store/payments/types'
export default paymentMixins<ChequePayment>()

</script>
