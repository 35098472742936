<template>
  <div class="mb-1">
    <reg-form-radio v-if="paymentSources.length" stacked v-model="selectedPaymentMethod" label="Source for monthly instalments" :$v="$v.selectedPaymentMethod" :field-name="descriptionForCardOrAccount" :description="`This ${descriptionForCardOrAccount} will be used to pay for the monthly payments`">
      <b-form-radio v-for="source in paymentSources" :key="source.id" :value="source.id">
        <template v-if="source.type === 'card'">
          <component :is="icons[source.brand]" v-if="icons[source.brand]" style="height: 1em; width: 2em;" />
          <span v-else v-text="source.brand" />
          •••• {{ source.last4 }} <span class="text-muted">{{ source.expMonth}} / {{ source.expYear }}</span>
          <span class="text-warning small d-block" v-if="showExpiryWarning(source.expMonth, source.expYear)">⚠️ This card has an expiry date before the first payment is due</span>
        </template>
        <template v-else-if="source.type === 'bacs_debit'">
          🏦 {{ source.sortCode }} ...{{ source.last4 }}
        </template>
        <template v-else v-text="source.type">
        </template>
      </b-form-radio>
    </reg-form-radio>
    <b-form-row v-if="enteringSite">
      <b-col offset="4">
        <b-spinner />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="4">
        <template v-if="!paymentSources.length">
          Source for monthly instalments
        </template>
      </b-col>
      <b-col>
        <b-button-group>
          <b-button v-for="paymentMethod in paymentMethodsAllowed" :key="paymentMethod.type" :disabled="!!leavingSite" :title="`Click here to add ${paymentMethod.title}`" v-b-tooltip.hover @click="addAnotherCard(paymentMethod.type)" variant="primary" size="sm">
            {{ paymentMethod.label }}
            <b-spinner v-if="leavingSite === paymentMethod.type" small />
          </b-button>
        </b-button-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ALLOWED_STRIPE_SUBSCRIPTION_PAYMENT_METHODS } from '../../constants'
import { IrisStore, IrisState, IrisGetters } from '../../store/types'
import { Stripe } from 'stripe'
import { createNamespacedHelpers } from 'vuex'
import { ComputedOptions } from 'vue/types'
import { InstalmentState, InstalmentGetters } from '../../store/instalments'
import brandIcons from '@iris/assets/cardLogos'

const { mapState, mapGetters } = createNamespacedHelpers('instalments')

export default Vue.extend({
  inject: ['$feathers'],
  props: {
    $v: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      /** flag for showing leaving site spinner value is the current selected method when leaving */
      leavingSite: '',
      /** flag when coming back and loading stripe data in for payment method */
      enteringSite: false
    }
  },
  computed: {
    descriptionForCardOrAccount (): string {
      const methods = []
      if (ALLOWED_STRIPE_SUBSCRIPTION_PAYMENT_METHODS.includes('bacs_debit')) {
        methods.push('bank account')
      }
      if (ALLOWED_STRIPE_SUBSCRIPTION_PAYMENT_METHODS.includes('card')) {
        methods.push('card')
      }
      return methods.join(' or ')
    },
    paymentMethodsAllowed () {
      const paymentData: {
        label: string;
        type: Stripe.PaymentMethod.Type;
        title: string;
      }[] = []
      if (ALLOWED_STRIPE_SUBSCRIPTION_PAYMENT_METHODS.includes('bacs_debit')) {
        paymentData.push({
          label: '🏦 Bank Account',
          type: 'bacs_debit',
          title: 'your bank account'
        })
      }
      if (ALLOWED_STRIPE_SUBSCRIPTION_PAYMENT_METHODS.includes('card')) {
        paymentData.push({
          label: '💳 Card',
          type: 'card',
          title: 'another card'
        })
      }
      return paymentData
    },
    icons (): Record<string, VueConstructor<Vue>> {
      return brandIcons
    },
    sessionId (): string | null {
      return this.urlSearchParams.get('sessionId')
    },
    cancelled (): boolean {
      return this.urlSearchParams.get('stripeCancelled') === '1'
    },
    urlSearchParams (): URLSearchParams {
      return new URLSearchParams(window.location.search)
    },
    selectedPaymentMethod: {
      get (this: Vue) {
        return (this.$store.state as IrisState).instalments.selectedPaymentMethod
      },
      set (this: Vue, value) {
        this.$store.commit('instalments/setSelectedPaymentMethod', value)
      }
    } as ComputedOptions<string | null>,
    ...(mapGetters(['firstPaymentDue']) as {
      firstPaymentDue: () => InstalmentGetters['firstPaymentDue']
    }),
    // only show allowed payment types
    paymentSources (): InstalmentState['availablePaymentMethods'] {
      return (this.$store as IrisStore).state.instalments.availablePaymentMethods.filter(s => ALLOWED_STRIPE_SUBSCRIPTION_PAYMENT_METHODS.includes(s.type))
    }
  },
  mounted () {
    // only comes from successful redirect
    if (this.sessionId) {
      this.enteringSite = true
      this.$store.dispatch('instalments/addPaymentSourceFromCheckoutSession', this.sessionId).then(paymentId => {
        this.selectedPaymentMethod = paymentId
      }).finally(() => {
        this.enteringSite = false
      })
    }
    // from any stripe redirect success or failure
    if (this.sessionId || this.cancelled) {
      // coming back from stripe replace the url and scroll ourselves into view
      window.history.replaceState(null, '', `${window.location.origin}${window.location.pathname}`)
      this.$el.scrollIntoView(true)
    }
  },
  methods: {
    showExpiryWarning (month: number, year: number): boolean {
      if (this.firstPaymentDue) {
        const expiryDate = (this.$store.getters as IrisGetters).moment().month(month - 1).year(year).endOf('month')
        return expiryDate.isSameOrBefore(this.firstPaymentDue)
      }
      return false
    },
    async addAnotherCard (paymentMethod?: Stripe.Checkout.SessionCreateParams.PaymentMethodType) {
      try {
        this.leavingSite = paymentMethod || 'unknown'
        const irisBackendSubscriptionId: string = await this.$store.dispatch('payments/setSubscription')
        const sessionService = this.$feathers.service('stripe/sessions')
        const sessionCreatedData = await sessionService.create({
          subscriberId: irisBackendSubscriptionId,
          successUrl: `${window.location.origin}${window.location.pathname}?sessionId={CHECKOUT_SESSION_ID}`,
          cancelUrl: `${window.location.origin}${window.location.pathname}?stripeCancelled=1`,
          paymentMethodTypes: paymentMethod ? [paymentMethod] : ['bacs_debit', 'card']
        })
        const stripe = await import('@stripe/stripe-js/pure').then(i => i.loadStripe(this.$config.public.stripePublishableKey))
        this.$root.$emit('disableBeforeUnload')
        const { error } = await stripe!.redirectToCheckout({
          sessionId: sessionCreatedData.checkoutSessionId!
        })
        if (error) {
          // left the site now this won't do anything need to handle the error
          this.$root.$emit('disableBeforeUnload', false)
          this.$store.commit('submitFail', `Failed to setup stripe Checkout: ${error.message}`)
        }
      } catch (e) {
        this.$store.commit('apiError', e)
      }
      this.leavingSite = ''
    }
  }
})
</script>
