<template>
  <div>
    <b-form-group :state="state" label="Enrolment code" :invalid-feedback="promoCodeFeedback" label-cols="4">
      <b-input-group>
        <b-form-input :state="state" class="col-md-4" size="sm" @keydown.esc.prevent="clearCode" @keydown.enter.prevent="applyCode" v-model.trim="code" placeholder="Enter Code" />
        <b-button slot="append" variant="primary" size="sm" @click="applyCode">Apply</b-button>
        <b-button slot="append" size="sm" @click="clearCode">Clear</b-button>
        <template slot="append">
          <b-form-checkbox class="ml-4" v-model="forcePickupSale">PUE</b-form-checkbox>
        </template>
      </b-input-group>
    </b-form-group>
    <b-list-group class="offset-md-4" v-if="promoCodes.length > 0">
      <b-list-group-item v-for="codeData in promoCodes" :key="codeData.code">
        {{ codeData.code }} {{ codeData.description ? ` - ${codeData.description}` : '' }}
        <b-button class="float-right" variant="outline-danger" size="sm" @click="removePromoCode(codeData.code)">Remove</b-button>
      </b-list-group-item>
    </b-list-group>
    <reg-form-radio v-if="!fixedSubscriptionModelEnabled" class="mt-2" v-model="paymentMethod" :$v="$v.finance.paymentMethod" field-name="Payment Method" label="Payment method" :options="paymentMethodsList" />
    <altPayerInformation :$v="$v.altPayerInformation" :loqateMessages="loqateMessages" />
  </div>
</template>

<script>

import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters, mapState } from 'vuex'
import AltPayerInformation from './AltPayerInformation'

export default {
  components: { AltPayerInformation },
  data () {
    return {
      code: '',
      promoCodeFeedback: '',
      state: null
    }
  },
  props: {
    $v: {
      type: Object,
      required: true
    },
    loqateMessages: {
      type: Object,
      required: true
    },
    paymentMethods: {
      type: Array,
      required: false
    }
  },
  watch: {
    code () {
      this.state = null
      this.promoCodeFeedback = ''
    }
  },
  computed: {
    ...mapFields([
      'finance.paymentMethod',
      'finance.salesCode',
      'forcePickupSale'
    ]),
    ...mapGetters({
      promoCodes: 'promoCodes',
      promoCodeData: 'promoCodeData',
      masterPaymentMethods: 'paymentMethods'
    }),
    ...mapState([
      'fixedSubscriptionModelEnabled'
    ]),
    promoCodeValid () {
      return !!this.promoCodeData(this.code)
    },
    paymentMethodsList () {
      return this.paymentMethods || this.masterPaymentMethods
    }
  },
  methods: {
    ...mapActions([
      'applyPromoCode',
      'removePromoCode'
    ]),
    clearCode () {
      this.code = ''
      this.promoCodeFeedback = ''
      this.state = null
    },
    applyCode () {
      if (!this.code) {
        this.promoCodeFeedback = `Please enter an enrolment code`
        this.state = false
      } else if (this.promoCodes.some(p => p.code.toUpperCase() === this.code.toUpperCase())) {
        this.promoCodeFeedback = `Enrolment code '${this.code}' is already active`
        this.state = false
      } else if (!this.promoCodeValid) {
        this.promoCodeFeedback = `Enrolment code '${this.code}' is not valid`
        this.state = false
      } else {
        // code is valid apply and clear state
        this.applyPromoCode(this.code)
        this.clearCode()
      }
    }
  }
}
</script>
