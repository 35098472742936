<template>
  <payment-common>
    <template #header>
      <b-alert variant="primary" v-if="processing" show>Saving card details</b-alert>
      <b-alert variant="warning" v-if="errorMessage" show dismissible>
        {{ errorMessage }}
      </b-alert>
      <b-alert variant="success" v-if="internalValue.finalised" show dismissible>Card details successfully saved</b-alert>
    </template>
    <deferred-deposit-field />
    <stripe-elements v-if="!internalValue.paymentMethod" />
    <reg-form-input v-if="internalValue.finalised && internalValue.receiptNumber" plaintext label="Receipt Number" :value="internalValue.receiptNumber" />
    <template #footer v-if="!internalValue.finalised">
      <p class="small text-muted text-right">
        To verify your details for this payment date, it is possible your card provider may require a temporary authorisation to be taken
      </p>
      <spinner class="mt-3" v-if="processing" color="rgb(24, 108, 149)" />
      <b-button class="float-right ml-3" variant="primary" @mouseover="$v.paidBy.$touch" @click="processDetails" :disabled="!canEdit || !cardDetailsOk">Setup future payment for {{ $n(amount, 'currency') }}</b-button>
    </template>
  </payment-common>
</template>

<script lang="ts">
import stripeMixin from './stripeElementsMixin'
import { DeferredPaymentApi } from '@iris/store/payments/deferredApi'
import { StripeDeferredDepositPayment, DeferredDepositPayment } from '@iris/store/payments/types'
import { IrisStore, IrisGetters } from '../../store/types'
import deferredMixins from './deferredMixins'
import * as Sentry from '@sentry/browser'
import spinner from 'vue-spinner/src/RiseLoader.vue'
import { TIMEZONE } from '@iris/constants'
import moment from 'moment-timezone'

export default stripeMixin<StripeDeferredDepositPayment>().extend({
  mixins: [ deferredMixins ],
  inject: [ '$feathers' ],
  components: {
    spinner
  },
  computed: {
    canRemove (): boolean {
      return true
    },
    canRemoveEnabled (): boolean {
      return !this.processing && (this.canEdit || this.internalValue.finalised)
    },
    api (): DeferredPaymentApi {
      return new DeferredPaymentApi(this.$store as IrisStore, this.$feathers!)
    },
    errorMessage (): string | undefined | null {
      if (this.isDeclinedError) {
        return 'At this time, the temporary authorisation has been declined, please select an alternative card'
      }
      return this.error && this.error.message
    },
    isDeclinedError (): boolean {
      return !!this.error && 'code' in this.error && this.error.code === 'card_declined' && this.error.decline_code === 'insufficient_funds'
    }
  },
  methods: {
    async processDetails () {
      this.processing = true
      this.error = null
      try {
        this.internalValue = await this.api.createOrPatch(this.internalValue)
        if (!this.internalValue.paymentMethod) {
          let result = await this.$stripe!.confirmCardSetup(this.internalValue.clientSecret!, {
            payment_method: {
              card: this.$cardNumber!,
              billing_details: {
                address: {
                  city: this.payerDetails.city,
                  country: this.payerDetails.country,
                  line1: this.payerDetails.address1,
                  line2: this.payerDetails.address2,
                  postal_code: this.payerDetails.postcode
                },
                email: this.payerDetails.email,
                name: `${this.payerDetails.title} ${this.payerDetails.firstName} ${this.payerDetails.lastName}`
              }
            }
          })
          this.$set(this.internalValue, 'debugResponse', result) // save the result into store for debugging purposes
          if (result.error) {
            this.error = result.error
          } else if (result.setupIntent) {
            this.internalValue.intentStatus = result.setupIntent.status
            if (this.internalValue.intentStatus === 'succeeded') {
              this.$set(this.internalValue, 'paymentMethod', result.setupIntent.payment_method)
            } else {
              this.error = { message: `No error returned but payment is not successful, Payment Status: ${this.internalValue.intentStatus}` }
            }
          }
        }
        if (this.internalValue.paymentMethod) {
          // save the card details back on the server and associate with the customer
          this.internalValue = await this.api.createFuturePayment(this.internalValue)
          this.internalValue.finalised = true
          // set direct debit date to 1 month post this date
          if ((this.$store as IrisStore).state.fixedSubscriptionModelEnabled) {
            this.$store.commit('instalments/setFirstPaymentDue', moment.tz(this.internalValue.dueOn, TIMEZONE).add(1, 'month').format('YYYY-MM-DD'))
          }
        }
      } catch (error) {
        Sentry.captureException(error)
        this.error = error
      }
      this.processing = false
    },
    async remove () {
      this.processing = true
      // once finalised you can't remove it.
      try {
        // this will cancel the payment intent on stripes end
        if (this.internalValue.paymentId) {
          try {
            this.internalValue = await this.api.remove(this.internalValue)
          } catch (e) {
            // this is ok it's the next item which is required
          }
        }
        if (this.internalValue.scheduledPaymentId) {
          this.internalValue = await this.api.removeScheduledPayment(this.internalValue)
        }
        this.$emit('remove')
      } catch (e) {
        this.error = e
      }
      this.processing = false
    }
  }
})
</script>
