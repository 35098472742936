<template>
  <payment-common>
    <template #header>
      <b-alert variant="primary" v-if="processing" show>Submitting card payment</b-alert>
      <b-alert variant="warning" v-if="error" show dismissible>{{ error.message }}</b-alert>
      <b-alert variant="success" v-if="internalValue.finalised" show dismissible>Payment successfully processed</b-alert>
    </template>
    <stripe-elements />
    <reg-form-input v-if="internalValue.finalised && internalValue.receiptNumber" plaintext label="Receipt Number" :value="internalValue.receiptNumber" />
    <template #footer v-if="!internalValue.finalised">
      <spinner class="mt-3" v-if="processing" color="rgb(24, 108, 149)" />
      <b-button class="float-right ml-3" variant="primary" @mouseover="$v.paidBy.$touch" @click="processPayment" :disabled="!canEdit || !cardDetailsOk">Process payment for {{ $n(amount, 'currency') }}</b-button>
    </template>
  </payment-common>
</template>

<script lang="ts">
import stripeMixin from './stripeElementsMixin'
import { StripeCreditCardPayment } from '@iris/store/payments/types'
import PaymentsApi from '@iris/store/payments/api'
import { IrisStore } from '../../store/types'
import spinner from 'vue-spinner/src/RiseLoader.vue'
import * as Sentry from '@sentry/browser'
import { Id } from '@feathersjs/feathers'

export default stripeMixin<StripeCreditCardPayment>().extend({
  inject: ['$feathers'],
  components: {
    spinner
  },
  computed: {
    api (): PaymentsApi {
      return new PaymentsApi(this.$store as IrisStore, this.$feathers!)
    }
  },
  methods: {
    async processPayment () {
      this.processing = true
      this.error = null
      try {
        // create a stripe customer record
        const subscription = await (this.$store.dispatch('payments/setSubscription') as Promise<Id>)
        // we need a payment intent with the amount set, so either create it or update it again
        this.internalValue = await this.api.createOrPatch(this.internalValue, {
          subscription
        })
        // depreciated renamed to confirmCardPayment with new args
        let result = await this.$stripe!.confirmCardPayment(this.internalValue.clientSecret!, {
          payment_method: {
            card: this.$cardNumber!,
            billing_details: {
              address: {
                city: this.payerDetails.city,
                country: this.payerDetails.country,
                line1: this.payerDetails.address1,
                line2: this.payerDetails.address2,
                postal_code: this.payerDetails.postcode
              },
              email: this.payerDetails.email,
              name: `${this.payerDetails.title} ${this.payerDetails.firstName} ${this.payerDetails.lastName}`
            }
          }
        })
        this.$set(this.internalValue, 'debugResponse', result) // save the result into store for debugging purposes
        if (result.error) {
          this.error = result.error
        } else if (result.paymentIntent) {
          this.internalValue.intentStatus = result.paymentIntent.status
          if (this.internalValue.intentStatus === 'succeeded') {
            this.internalValue.finalised = true
          } else {
            this.error = { message: `No error returned but payment is not successful, Payment Status: ${this.internalValue.intentStatus}` }
          }
        }
      } catch (error) {
        Sentry.captureException(error)
        this.error = error
      }
      this.processing = false
    },
    async remove () {
      this.processing = true
      // once finalised you can't remove it.
      if (!this.internalValue.finalised) {
        try {
          // this will cancel the payment intent on stripes end
          this.internalValue = await this.api.remove(this.internalValue)
        } catch (e) {
          // remove anyway
        }
        this.$emit('remove')
      }
      this.processing = false
    }
  }
})
</script>
<style>
  .form-control.custom-focus {
    color: #495057;
    /* background-color: #fff; */
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .form-control.is-valid.custom-focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  .form-control.is-invalid.custom-focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .stripe-disabled .form-control {
    background-color: #e9ecef;
  }

</style>
