<template>
  <iris-lite>
    <slot />
  </iris-lite>
</template>

<script lang="ts" setup>
import IrisLite from '@iris/IrisLite.vue'
import type LoqateApi from '@iris/loqateApi'
import type Api from '@iris/api'
import type { RawLocation } from 'vue-router'
import { IrisStore } from '@iris/store/types'
import { Application } from '@feathersjs/feathers'
import { ServiceTypes } from '@iris/feathersjs'
import { IRegModelMap } from '@iris/regmodel'
import { DefaultApolloClient } from '@vue/apollo-composable'
import i18n from '@iris/i18n'

export type PropsType = {
  store: IrisStore
  loqateApi: LoqateApi
  backLink: RawLocation
  irisApi: Api
  param: (name: string) => IRegModelMap['key']
  feathers: Application<ServiceTypes>
}

const props = defineProps<PropsType>()

const instance = getCurrentInstance()!
// this will override the vuex store in all components from this one and all children
instance.proxy.$store = props.store
provide('irisApi', props.irisApi)
provide('$loqate', props.loqateApi)
provide('backLink', props.backLink)
provide('param', props.param)
provide('$feathers', props.feathers)
provide(DefaultApolloClient, props.store.nestApi.apolloClient())

</script>

<script lang="ts">

export default defineComponent({
  i18n
})

</script>