<template>
  <payment-common>
    <b-form-group label-cols="4" breakpoint="md" v-if="canEdit">
      <b-button v-b-tooltip.hover title="Open a new tab with the old In-Home Initial Payment form pre-filled in" :href="paymentUrl" variant="primary" target="_blank">Process Payment</b-button>
    </b-form-group>
    <reg-form-input :disabled="!canEdit" :$v="$v.receiptNumber" field-name="Enter Number" label="Receipt Number" v-model="receiptNumber" description="This number comes from the exemplar deposits page once the payment is processed enter the number in here" />
  </payment-common>
</template>

<script>
import { compact as _compact } from 'lodash'
import paymentMixins from './mixin'
import { stringify } from 'query-string'

export default paymentMixins().extend({
  computed: {
    paymentUrl () {
      let state = this.$store.state

      let loqateResponse = state.address.loqateResponse || {}
      const paramsForPayment = {
        title: state.familyInformation.title, // need to fix map
        firstName: state.familyInformation.firstName,
        lastName: state.familyInformation.lastName,
        houseNumber: _compact([loqateResponse.SubBuilding, loqateResponse.BuildingNumber, loqateResponse.BuildingName]).join(' '),
        streetName: loqateResponse.Street,
        postCode: state.address.postcode,
        email: state.familyInformation.email,
        userName: (state.createAccountResult || {}).parentUsername,
        programmeType: state.finance.paymentMethod,
        courseAdvisor: state.consultant,
        branchOffice: state.branchOffice,
        paymentAmount: this.amount
      }
      return `https://www.exemplar-education.com/deposits/?${stringify(paramsForPayment)}`
    },
    receiptNumber: {
      get () {
        return this.internalValue.receiptNumber
      },
      set (value) {
        this.$set(this.internalValue, 'receiptNumber', value)
      }
    }
  }
})

</script>
