<template>
  <reg-form-select class="col-md-8 col-lg-7" label-cols="6" v-model="branchOffice" :options="branchOfficesArray" :$v="$v" field-name="Family's Home Office" label="Family's Home Office" />
</template>

<script>

import { mapFields } from 'vuex-map-fields'
import { findIndex as _findIndex } from 'lodash'

import branchOffices from '@iris/branchOffices'

export default {
  props: {
    $v: {
      type: Object,
      required: true
    }
  },
  computed: {
    branchOfficesArray () {
      if (_findIndex(branchOffices, { value: this.branchOffice }) === -1) {
        branchOffices.push({
          value: this.branchOffice,
          text: `${this.branchOffice} Branch`
        })
      }
      return branchOffices
    },
    ...mapFields([
      'branchOffice'
    ])
  }
}
</script>
