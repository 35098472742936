import { Service, Application } from '@feathersjs/feathers'
import { ISubscriptionSetupIntent, ServiceTypes, IScheduledPayment } from '@iris/feathersjs'
import { Store } from 'vuex'
import { IrisGetters, IrisState } from '../types'
import { StripeDeferredDepositPayment, StripePaymentPlan } from './types'
import moment from 'moment-timezone'
import { TIMEZONE } from '@iris/constants'

export class DeferredPaymentApi {
  private store: Store<IrisState>
  private client: Application<ServiceTypes>

  constructor (store: Store<IrisState>, client: Application<ServiceTypes>) {
    this.store = store
    this.client = client
  }

  async createOrPatch (payment: StripeDeferredDepositPayment): Promise<StripeDeferredDepositPayment> {
    if (payment.paymentId) {
      return this.patch(payment)
    } else {
      return this.create(payment)
    }
  }

  private async setupIntent (): Promise<Service<ISubscriptionSetupIntent>> {
    return this.client.service(`subscriptions/${await this.store.dispatch('payments/setSubscription')}/setup-intent`)
  }

  /**
   * Create the payments required to implement the payment plan
   * @param paymentPlan
   */
  async createPaymentPlanFuturePayments (paymentPlan: StripePaymentPlan): Promise<StripePaymentPlan> {
    if (!paymentPlan.instalmentsSchedule) {
      throw new Error('No Payment schedule setup')
    }
    const subscription = await this.store.dispatch('payments/setSubscription')
    const futurePaymentResults = await this.client.service('stripe/scheduled-payment').create(paymentPlan.instalmentsSchedule.map<Partial<IScheduledPayment>>(installment => {
      return {
        amountInCents: installment.amountInCents,
        debitOn: moment.tz(installment.date, TIMEZONE).startOf('day').toISOString(),
        description: `${(this.store.getters as IrisGetters).shortCompanyName} installment ${installment.monthNumber} of ${paymentPlan.instalmentsSchedule!.length}`,
        payment_method: paymentPlan.paymentMethod,
        subscription,
        subscriberOffice: this.store.state.branchOffice,
        postcode: this.store.state.address.postcode,
        subscriberId: this.store.state.createAccountResult!.subscriberId,
        surname: this.store.state.familyInformation.lastName
      }
    }))
    return {
      ...paymentPlan,
      scheduledPaymentIds: futurePaymentResults.map(r => `${r._id}`)
    }
  }

  async createFuturePayment (payment: StripeDeferredDepositPayment): Promise<StripeDeferredDepositPayment> {
    const i18n = await import('../../i18n')
    let futurePaymentResult = await this.client.service('stripe/scheduled-payment').create({
      amountInCents: payment.amountInCents,
      debitOn: moment.tz(payment.dueOn, TIMEZONE).startOf('day').toISOString(),
      description: i18n.default.t(`payments.title.${payment.type}`) as string,
      payment_method: payment.paymentMethod,
      subscription: await this.store.dispatch('payments/setSubscription'),
      subscriberOffice: this.store.state.branchOffice,
      postcode: this.store.state.address.postcode,
      subscriberId: this.store.state.createAccountResult!.subscriberId,
      surname: this.store.state.familyInformation.lastName
    })
    return {
      ...payment,
      scheduledPaymentId: futurePaymentResult._id ? String(futurePaymentResult._id) : undefined
    }
  }

  async removeScheduledPayment (payment: StripeDeferredDepositPayment): Promise<StripeDeferredDepositPayment> {
    if (payment.scheduledPaymentId) {
      await this.client.service('stripe/scheduled-payment').remove(payment.scheduledPaymentId)
    }
    return {
      ...payment,
      scheduledPaymentId: undefined
    }
  }

  async create (payment: StripeDeferredDepositPayment): Promise<StripeDeferredDepositPayment> {
    let paymentResult = await (await this.setupIntent()).create({})
    return {
      ...payment,
      intentStatus: paymentResult.intent.status,
      paymentId: paymentResult._id,
      clientSecret: paymentResult.intent.client_secret || undefined
    }
  }

  async patch (payment: StripeDeferredDepositPayment): Promise<StripeDeferredDepositPayment> {
    if (!payment.paymentId) {
      throw new Error('payment not saved yet!')
    }
    let paymentResult = await (await this.setupIntent()).patch(payment.paymentId, {})
    return {
      ...payment,
      intentStatus: paymentResult.intent.status,
      paymentId: paymentResult._id!,
      clientSecret: paymentResult.intent.client_secret || undefined
    }
  }

  async remove (payment: StripeDeferredDepositPayment): Promise<StripeDeferredDepositPayment> {
    if (!payment.paymentId) {
      throw new Error('payment not saved yet!')
    }
    let paymentResult = await (await this.setupIntent()).remove(payment.paymentId)
    return {
      ...payment,
      intentStatus: paymentResult.intent.status,
      paymentId: undefined,
      clientSecret: paymentResult.intent.client_secret || undefined
    }
  }
}
