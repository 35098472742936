<template>
    <payment-common>
        <b-form-group label-cols="4" breakpoint="md" v-if="stripePaymentLink">
            <b-button v-b-tooltip.hover title="Send Payment link via SMS to customer" :disabled="!validData" @click="confirmSendSms" variant="primary">Send Payment Link</b-button>
        </b-form-group>
    </payment-common>
</template>

<script lang="ts">
import paymentMixins from './mixin'
import { KlarnaPayment } from '@iris/store/payments/types'
import { KLARNA_STRIPE_PAYMENT_LINKS } from '@iris/constants'
import { useSendSmsMutation } from '../../queries'
import { IAddressInfo, IPersonInfo, IrisStore } from '../../store/types'
import { formatMobilePhone } from '../../store/helpers'

const mixin = paymentMixins<KlarnaPayment>()
export default defineComponent({
    mixins: [mixin],
    setup() {
        const currentInstance = getCurrentInstance()!
        const instance = currentInstance.proxy as unknown as InstanceType<typeof mixin>
        const store = instance.$store as IrisStore
        const { mutate: sendSms } = useSendSmsMutation({})
        const payerDetails = computed< IPersonInfo & IAddressInfo>(() => {
            return store.getters.getInfoForPayerType(instance.internalValue.paidBy)
        })
        const stripePaymentLink = computed(() => {
            return KLARNA_STRIPE_PAYMENT_LINKS.find(l => l.amount === instance.amount)?.link
        })

        const validData = computed(() => {
            return !!payerDetails.value.firstName
        })

        return {
            stripePaymentLink,
            validData,
            confirmSendSms () {
                return instance.$bvModal.msgBoxConfirm(`You are about to send an SMS to ${payerDetails.value.firstName} at ${store.state.familyInformation.mobilePhone} containing the payment link for £${instance.moneyNumberOnly(instance.amount)} for their initial 6 months subscription to Estia Labs via Klarna.`, {
                    title: 'Send Payment Link via SMS',
                    okTitle: 'Send SMS'
                }).then((resp: boolean) => {
                    if (resp) {
                        return sendSms({
                            message: `Hi ${payerDetails.value.firstName}, please click this link to pay £${instance.moneyNumberOnly(instance.amount)} for your initial 6 months subscription to Estia Labs via Klarna. ${stripePaymentLink.value} Thanks!`,
                            phone: formatMobilePhone(store.state.familyInformation.mobilePhone)
                        }).then(() => {
                            return instance.$bvModal.msgBoxOk('SMS sent successfully. Thank you!')
                        }).catch(e => {
                            console.error(e)
                            return instance.$bvModal.msgBoxOk(`Failed to send SMS. Here is the link to pass to the customer: ${stripePaymentLink.value}`)
                        })
                    }
                })
            }
        }
    }
})
</script>
