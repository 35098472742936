<template>
  <div v-if="!loading">
    <b-jumbotron v-if="!customerHasPageOpen" header="Waiting for customer to open confirmation page" lead="This will update once the customer has opened the page">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Watiing..."></b-spinner>
      </div>
      <b-button variant="primary" @click="resendSms()" :disabled="sendingSms">Resend SMS</b-button>
      <b-button class="ml-2" variant="primary" @click="resendSms(true)" :disabled="sendingSms">Resend as Email</b-button>
    </b-jumbotron>
    <b-jumbotron v-else header="Waiting for customer" lead="Waiting for the customer to confirm their subscription.">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Watiing..."></b-spinner>
      </div>
      <ul>
        <li>Got Location: {{ haveLocation ? '✔️' : '❌' }}</li>
        <li>Last Activity: {{ lastActivity || '❌' }}</li>
      </ul>
    </b-jumbotron>
  </div>
</template>

<script lang="ts">
import { IrisSalesState, useGetSalesConfirmationStatusQuery, useWatchCustomerEventsSubscription, useResendSmsToCustomerMutation, MessageType } from '@iris/queries'
import { IrisStore } from '@iris/store/types'
import { computed, defineComponent, getCurrentInstance, ref, watch } from 'vue'

export default defineComponent({
  setup () {
    const customerHasPageOpen = ref(false)
    const customerHasSigned = ref(false)
    const sendingSms = ref(false)
    const lastActivity = ref('')
    const haveLocation = ref(false)
    const instance = getCurrentInstance()!
    const store: IrisStore = instance.proxy.$store as IrisStore
    const currentStatus = useGetSalesConfirmationStatusQuery(computed(() => ({
      id: store.state.newSigningProcessConfirmationId!
    })))

    currentStatus.onResult(result => {
      customerHasPageOpen.value = result.data?.saleConfirmationById.activePagesOpen > 0
      customerHasSigned.value = !!result.data?.saleConfirmationById.confirmedAt
    })
    const resendSms = useResendSmsToCustomerMutation({})

    const customerStatus = useWatchCustomerEventsSubscription(computed(() => ({
      id: store.state.newSigningProcessConfirmationId!
    })), computed(() => ({
      enabled: !!store.state.newSigningProcessConfirmationId
    })))
    customerStatus.onResult(result => {
      if (result.data) {
        customerHasPageOpen.value = result.data.salesConfirmationEvent.activePagesOpen > 0
        if (result.data.salesConfirmationEvent.action === IrisSalesState.CustomerAccept) {
          customerHasSigned.value = true
        }
        if (result.data.salesConfirmationEvent.position && result.data.salesConfirmationEvent.position.hasPosition) {
          haveLocation.value = true
        }
        if (result.data.salesConfirmationEvent.action === IrisSalesState.CustomerActivityOnPage) {
          lastActivity.value = result.data.salesConfirmationEvent.message
        }
      }
    })
    // wait for ok
    watch(customerHasSigned, (value) => {
      if (value) {
        instance.proxy.$emit('ok')
      }
    })

    return {
      loading: currentStatus.loading,
      customerHasPageOpen,
      lastActivity,
      haveLocation,
      sendingSms,
      resendSms (forceEmail = false) {
        sendingSms.value = true
        store.commit('updateField', { path: 'flashMessage', value: `Resending ${forceEmail ? 'as email' : 'text message'}` })
        return resendSms.mutate({
          id: store.state.newSigningProcessConfirmationId!,
          type: forceEmail ? MessageType.Email : MessageType.Sms
        }).then((result) => {
          if (result && result.data) {
            if (result.data.salesConfirmationResendSms.messageType === MessageType.Email) {
              if (forceEmail) {
                store.commit('updateField', { path: 'flashMessage', value: `Email sent to ${result.data.salesConfirmationResendSms.email}` })
              } else {
                store.commit('updateField', { path: 'flashMessage', value: `SMS Failed to send please check ${result.data.salesConfirmationResendSms.email} for link` })
              }
            } else {
              store.commit('updateField', { path: 'flashMessage', value: `Text message sent to ${result.data.salesConfirmationResendSms.phoneNumber}` })
            }
          } else {
            store.commit('updateField', { path: 'flashMessage', value: null })
          }
          sendingSms.value = false
        })
      }
    }
  }
})
</script>
