<template>
  <div>
    <b-alert show>
      <h5>The family learning account has now been created</h5>
      <table class="table table-striped">
        <thead>
          <tr>
            <td></td>
            <th>Username</th>
            <th>Password</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              {{ createAccountResult.parentFirstName }}
              {{ createAccountResult.parentLastName }}
            </th>
            <td>{{ createAccountResult.parentUsername }}</td>
            <td>{{ createAccountResult.parentPassword }}</td>
          </tr>
          <tr
            v-for="(student, idx) in createAccountResult.children"
            :key="idx"
          >
            <th scope="row">
              {{ student.firstName }} {{ student.lastName }}
            </th>
            <td>{{ student.loginName }}</td>
            <td>{{ student.password }}</td>
          </tr>
        </tbody>
      </table>
    </b-alert>
    <p>The Welcome email will have been received by the customer. Please confirm this has been received.</p>
    <b-row>
      <b-col>
        <small>
          Didn't receive the email? <br />
          <b-button variant="danger" size="sm" v-b-modal.resendEmails>Click here to resend</b-button>
        </small>
      </b-col>
      <b-col class="text-right">
        <b-button variant="success" size="lg" @click="$emit('ok')">If Yes, click here to continue</b-button>
      </b-col>
    </b-row>
    <b-modal ref="resendEmails" id="resendEmails" title="Resend welcome email" :ok-disabled="$v.newEmail.$pending || $v.newEmail.$invalid" @shown="newEmail = email" @ok="resendEmail">
      <p>Email</p>
      <reg-form-input type="email" placeholder="Email" :messages="{loqate: loqateMessage , email: 'Email is invalid'}"
        :$v="$v.newEmail" v-model.trim.lazy="newEmail" description="If the email was incorrect you can fix it here and it will update the previously entered value" />
    </b-modal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'
import { required, email, maxLength } from 'vuelidate/lib/validators'

import { loqateValidator } from '../validations'

export default {
  inject: ['$loqate'],
  data () {
    return {
      newEmail: null,
      loqateMessage: null
    }
  },
  computed: {
    ...mapFields([
      'familyInformation.email'
    ]),
    ...mapState(['createAccountResult'])
  },
  validations: {
    newEmail: {
      required,
      email,
      loqate: loqateValidator(function (response) {
        this.loqateMessage = response.ResponseMessage
      }),
      maxLength: maxLength(128)
    }
  },
  methods: {
    resendEmail (_evt) {
      this.$store.dispatch('updateAndResendAccountEmails', this.newEmail)
    }
  }
}
</script>
