<template>
  <payment-common />
</template>

<script lang="ts">
import paymentMixins from './mixin'
import { CashPayment } from '@iris/store/payments/types'

export default paymentMixins<CashPayment>()
</script>
