<template>
  <b-jumbotron :header="header" :lead="lead">
    <p v-if="!noStatusWhileWaiting || !fetching">{{ statusMessage }}</p>
    <spinner v-if="fetching" style="margin: 3rem auto" color="rgb(24, 108, 149)" />
  </b-jumbotron>
</template>

<script>
// spinner
import spinner from 'vue-spinner/src/RiseLoader.vue'

const PERMISSION_DENIED = 1

export default {
  props: {
    waitingMessage: {
      type: String,
      default: 'Please wait while we are getting your location'
    },
    deniedMessage: {
      type: String,
      default: 'You must allow location access to continue. Please reload this page and accept the location prompt.'
    },
    successMessage: {
      type: String,
      default: 'We have got your location'
    },
    titleFetchingMessage: {
      type: String,
      default: 'Fetching Location'
    },
    leadMessage: {
      type: String,
      default: 'Please allow location access to continue'
    },
    noStatusWhileWaiting: {
      type: Boolean,
      default: false
    },
    noError: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rawPosition: null,
      rawError: null
    }
  },
  mounted () {
    try {
      navigator.geolocation.getCurrentPosition(pos => {
        this.rawPosition = pos
      }, error => {
        this.rawError = error
      })
    } catch (e) {
      this.rawError = e
    }
  },
  computed: {
    error () {
      let error = this.rawError
      if (!this.position && error) {
        return {
          code: error.code,
          message: error.message
        }
      }
      return null
    },
    position () {
      let pos = this.rawPosition
      if (pos) {
        return {
          timestamp: new Date(pos.timestamp).toISOString(),
          accuracy: pos.coords.accuracy,
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
          hasPosition: true,
          message: 'Have position'
        }
      }
      // allow types apart from permission denied
      // IE PositionError.TIME_OUT(3) & PositionError.POSITION_UNAVAILABLE(2)
      if (this.rawError && (this.noError || this.rawError.code !== PERMISSION_DENIED)) {
        return {
          timestamp: new Date().toISOString(),
          hasPosition: false,
          message: this.rawError.message
        }
      }
      return null
    },
    statusMessage () {
      if (this.fetching) return this.waitingMessage
      if (this.error) return this.deniedMessage
      if (this.position) return this.successMessage
      return null
    },
    message () {
      if (this.error) return this.error.message
      if (this.position) return this.position.message
      return null
    },
    header () {
      return this.fetching ? this.titleFetchingMessage : 'Result'
    },
    lead () {
      return this.message ? this.message : this.leadMessage
    },
    fetching () {
      return !this.position && !this.error
    }
  },
  watch: {
    position (position) {
      this.$emit('position', position)
    },
    error (error) {
      this.$emit('error', error)
    }
  },
  components: { spinner }
}
</script>
