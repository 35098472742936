<template>
  <payment-common>
    <deferred-deposit-field />
  </payment-common>
</template>

<script lang="ts">

import paymentMixins from './mixin'
import { DeferredDepositPayment } from '@iris/store/payments/types'
import { IrisGetters } from '../../store/types'
import moment from 'moment'
import deferredMixins from './deferredMixins'

export default paymentMixins<DeferredDepositPayment>().extend({
  mixins: [ deferredMixins ]
})

</script>
