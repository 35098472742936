import { StripePaymentPlan, StripeCreditCardPayment, StripeDeferredDepositPayment } from '@iris/store/payments/types'
import paymentMixins from './mixin'
import StripeElements from './StripeElements.vue'
import { IPersonInfo, IAddressInfo, IrisGetters } from '@iris/store/types'
import type { Stripe, StripeError, StripeCardNumberElement, StripeCardExpiryElement, StripeCardCvcElement, StripeCardCvcElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardNumberElementChangeEvent, StripeElementChangeEvent, StripeCardElementOptions } from '@stripe/stripe-js'

export default function<T extends StripePaymentPlan | StripeCreditCardPayment | StripeDeferredDepositPayment> () {
  return paymentMixins<T>().extend({
    components: {
      StripeElements
    },
    data () {
      return {
        processing: false,
        error: null as null | StripeError | { message: string },
        $cardNumber: null as null | StripeCardNumberElement,
        $cardExpiry: null as null | StripeCardExpiryElement,
        $cardCvc: null as null | StripeCardCvcElement,
        $stripe: null as null | Stripe,
        cvc: {} as {} | StripeCardCvcElementChangeEvent,
        expiry: {} as {} | StripeCardExpiryElementChangeEvent,
        number: {} as {} | StripeCardNumberElementChangeEvent
      }
    },
    mounted () {
      if (!this.internalValue.finalised) {
        this.initElements()
      }
    },
    beforeDestroy () {
      this.removeElements()
    },
    computed: {
      payerDetails (): IPersonInfo & IAddressInfo {
        return (this.$store.getters as IrisGetters).getInfoForPayerType(this.internalValue.paidBy)
      },
      canEdit (): boolean {
        return !(this.internalValue.finalised || this.processing)
      },
      cardDetailsOk (): boolean {
        return ([this.number, this.expiry, this.cvc] as StripeElementChangeEvent[]).every(field => field.complete) &&
          // ignore validations on finalised
          Object.keys(this.$v).filter(k => k[0] !== '$' && k !== 'finalised').every(key => !this.$v[key].$invalid)
      },
      stripeElementsOptions (): StripeCardElementOptions {
        return {
          classes: {
            invalid: 'is-invalid',
            complete: 'is-valid',
            base: 'form-control',
            focus: 'custom-focus'
          },
          iconStyle: 'default',
          disabled: !this.canEdit,
          style: {
            base: {
              color: 'rgb(73, 80, 87)',
              lineHeight: '24px',
              // spell-checker:disable
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              // fontSmoothing: 'antialiased',
              // spell-checker:enable
              fontSize: '16px',
              '::placeholder': {
                color: '#6c757d'
              }
            }
          }
        }
      }
    },
    watch: {
      stripeElementsOptions: function (value: StripeCardElementOptions) {
        if (this.$cardNumber) {
          this.$cardNumber.update({
            ...value,
            showIcon: true
          })
        }
        if (this.$cardExpiry) {
          this.$cardExpiry.update(value)
        }
        if (this.$cardCvc) {
          this.$cardCvc.update(value)
        }
      },
      'internalValue.finalised': function (finalised) {
        finalised ? this.removeElements() : this.initElements()
      }
    },
    methods: {
      initElements () {
        const createElements = () => {
          let elements = this.$stripe!.elements({ locale: 'en' })
          if (!this.$cardNumber && this.$refs.cardNumber) {
            this.$cardNumber = elements.create('cardNumber', {
              ...this.stripeElementsOptions,
              showIcon: true
            })
            this.$cardNumber.mount(this.$refs.cardNumber as HTMLElement)
            this.$cardNumber.on('change', event => {
              this.number = event || {}
            })
          }
          if (!this.$cardExpiry && this.$refs.cardExpiry) {
            this.$cardExpiry = elements.create('cardExpiry', this.stripeElementsOptions)
            this.$cardExpiry.mount(this.$refs.cardExpiry as HTMLElement)
            this.$cardExpiry.on('change', event => {
              this.expiry = event || {}
            })
          }
          if (!this.$cardCvc && this.$refs.cardCvc) {
            this.$cardCvc = elements.create('cardCvc', this.stripeElementsOptions)
            this.$cardCvc.mount(this.$refs.cardCvc as HTMLElement)
            this.$cardCvc.on('change', event => {
              this.cvc = event || {}
            })
          }
        }
        if (!this.$stripe) {
          import('@stripe/stripe-js/pure').then(i => i.loadStripe(this.$config.public.stripePublishableKey))
            .then(stripe => {
              this.$stripe = stripe
              createElements()
            })
        } else {
          createElements()
        }
      },
      removeElements () {
        if (this.$cardNumber) {
          this.$cardNumber.unmount()
          this.$cardNumber.destroy()
          this.$cardNumber = null
        }
        if (this.$cardExpiry) {
          this.$cardExpiry.unmount()
          this.$cardExpiry.destroy()
          this.$cardExpiry = null
        }
        if (this.$cardCvc) {
          this.$cardCvc.unmount()
          this.$cardCvc.destroy()
          this.$cardCvc = null
        }
        if (this.$stripe) {
          this.$stripe = null
        }
      }
    }
  })
}
