<template>
  <div v-if="['LTL', 'CASH', 'CP12'].indexOf(finalSaleType) > -1">
    <b-form-row>
      <reg-form-checkbox class="col-md-9" v-model="altPayer" field-name="Alternative Payer" />
    </b-form-row>
    <alt-payer-information-inner-form v-if="altPayer" :has-mobile="ltlExtraConfirmations" :$v="$v" :loqate-email-message="loqateMessages.altPayerEmail" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import altPayerInformationInnerForm from './AltPayerInformationInnerForm'

export default {
  components: { altPayerInformationInnerForm },
  props: {
    $v: {
      type: Object,
      required: true
    },
    loqateMessages: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['finalSaleType', 'ltlExtraConfirmations']),
    altPayer: {
      get () {
        return this.$store.getters.altPayer
      },
      set (value) {
        this.$store.commit('updateField', { path: 'altPayer', value })
      }
    }
  }
}
</script>
