
import { IrisState, IrisGetters, PaymentMethods } from '../types'

import { ServiceMethods, Application } from '@feathersjs/feathers'
import { StripeCreditCardPayment, StripePaymentPlan } from './types'
import { Store } from 'vuex'
import { IPaymentApi, ServiceTypes } from '@iris/feathersjs'

declare var VERSION: string

type PaymentTypesAllowed = StripeCreditCardPayment | StripePaymentPlan

export default class PaymentApi {
  payments: ServiceMethods<IPaymentApi>
  store: Store<IrisState>

  constructor (store: Store<IrisState>, client: Application<ServiceTypes>) {
    this.payments = client.service('payments')
    this.store = store
  }

  private paymentMethodToShortType (method: PaymentMethods): Exclude<PaymentMethods, 'APS_SUBSCRIPTION'> {
    if (method === 'APS_SUBSCRIPTION') {
      return 'APS'
    }
    return method
  }

  _paymentToModel (payment: PaymentTypesAllowed, extraParams: Partial<IPaymentApi> = {}): IPaymentApi {
    return {
      currency: 'gbp',
      amountInCents: payment.amountInCents,
      irisVersion: VERSION,
      receiptEmail: (this.store.getters as IrisGetters).getInfoForPayerType(payment.paidBy).email,
      saleType: this.paymentMethodToShortType(this.store.state.finance.paymentMethod),
      subscriberId: this.store.state.createAccountResult!.subscriberId,
      subscriberPostcode: this.store.state.address.postcode,
      subscriberSurname: this.store.state.familyInformation.lastName,
      userId: this.store.state.userId,
      regHitId: this.store.state.regHitId,
      consultant: this.store.state.consultant,
      office: this.store.state.branchOffice,
      ...(typeof payment.saveForFuturePayments === 'boolean' ? { setupFutureUsage: payment.saveForFuturePayments ? 'off_session' : 'on_session' } : {}),
      ...extraParams
    }
  }

  createOrPatch <T extends PaymentTypesAllowed> (payment: T, extraParams: Partial<IPaymentApi> = {}): Promise<T> {
    if (payment.paymentId) {
      return this.patch(payment, extraParams)
    } else {
      return this.create(payment, extraParams)
    }
  }

  async create <T extends PaymentTypesAllowed> (payment: T, extraParams: Partial<IPaymentApi> = {}): Promise<T> {
    let paymentResult = await this.payments.create(this._paymentToModel(payment, extraParams)) as IPaymentApi
    return {
      ...payment,
      intentStatus: paymentResult.stripeIntentStatus,
      paymentId: paymentResult._id,
      receiptNumber: paymentResult.stripePaymentIntentId,
      clientSecret: paymentResult.stripeClientSecret
    }
  }

  async patch <T extends PaymentTypesAllowed> (payment: T, extraParams: Partial<IPaymentApi> = {}): Promise<T> {
    if (!payment.paymentId) {
      throw new Error('payment not saved yet!')
    }
    let paymentResult = await this.payments.patch(payment.paymentId, this._paymentToModel(payment, extraParams))
    if ('length' in paymentResult) {
      paymentResult = paymentResult[0]
    }
    return {
      ...payment,
      intentStatus: paymentResult.stripeIntentStatus,
      paymentId: paymentResult._id,
      receiptNumber: paymentResult.stripePaymentIntentId,
      clientSecret: paymentResult.stripeClientSecret
    }
  }

  async remove <T extends PaymentTypesAllowed> (payment: T): Promise<T> {
    if (!payment.paymentId) {
      throw new Error('payment not saved yet!')
    }
    let paymentResult = await this.payments.remove(payment.paymentId)
    if ('length' in paymentResult) {
      paymentResult = paymentResult[0]
    }
    return {
      ...payment,
      intentStatus: paymentResult.stripeIntentStatus,
      receiptNumber: paymentResult.stripePaymentIntentId,
      paymentId: undefined,
      clientSecret: paymentResult.stripeClientSecret
    }
  }
}
