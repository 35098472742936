<template>
  <reg-form-radio :$v="$v" stacked v-model="monthsTerm" :options="revisionOptions" field-name="PAYG Revision payment term" />
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

export default {
  props: {
    $v: {
      type: Object,
      required: true
    }
  },
  watch: {
    revisionOptions (options) {
      if (!options.some(option => option.value === this.monthsTerm)) {
        this.monthsTerm = null
      }
    }
  },
  computed: {
    revisionOptions () {
      return this.irisRevisionPricing.filter(option => {
        return (Math.floor(option.deposit * 100) + this.totalOfOtherItemsPurchased) >= this.totalAmountPaidInCents
      }).map(option => ({
        text: `${this.$n(option.deposit, 'currency')} deposit and ${option.monthsTerm} monthly instalments of ${this.$n((option.total - option.deposit) / option.monthsTerm, 'currency')} (Total cost ${this.$n(option.total, 'currency')})`,
        value: option.monthsTerm
      }))
    },
    ...mapFields([
      'finance.monthsTerm'
    ]),
    ...mapGetters([
      'irisRevisionPricing',
      'totalOfOtherItemsPurchased'
    ]),
    ...mapGetters('payments', ['totalAmountPaidInCents'])
  }
}
</script>
