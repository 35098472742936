<template>
  <div class="form-row">
    <div class="col-lg-8 col-md-12">
      <h3>Family Information</h3>
      <p>
        <small class="text-muted">*denotes required fields</small>
      </p>
      <b-form-row>
        <reg-form-select class="col-md-2" v-model="title" :$v="$v.title" :options="titles" field-name="Title" />
        <reg-form-input class="col-md-5" :$v="$v.firstName" v-model.trim.lazy="firstName" field-name="First Name" />
        <reg-form-input class="col-md-5" :$v="$v.lastName" v-model.trim.lazy="lastName" field-name="Last Name" />
      </b-form-row>
      <b-form-row>
        <reg-form-input type="date" class="col-md-6" :$v="$v.dob" v-model="dob" :max="todaysDate" :messages="{minValue: 'Please enter a valid date', maxValue: 'Main parent must be over 18 years'}" field-name="DOB" label="Date of Birth" />
      </b-form-row>
      <b-form-row>
        <reg-form-input class="col-md-12" :$v="$v.authorisedPersons" v-model.trim.lazy="authorisedPersons" field-name="Authorised Persons" description="Enter names of other authorised persons for this account if any" />
      </b-form-row>
      <b-form-row>
        <reg-form-input class="col-md-5" type="email"
        :messages="{loqate: loqateMessages.email ,email: 'Parent 1 Email is invalid', duplicateEmailCheck: 'Email is already registered'}"
        :$v="$v.email" v-model.trim.lazy="email" field-name="Parent 1 Email" />
        <reg-form-input class="col-md-5" type="email" :messages="{sameAsEmail: 'Email Address Mismatch'}" :$v="$v.emailConfirm" v-model.trim.lazy="emailConfirm" field-name="Confirm Email" />
        <div class="col-md-2" />
      </b-form-row>
      <b-form-row>
        <reg-form-input class="col-md-5" type="email"
        :messages="{loqate: loqateMessages.alternativeEmail ,email: 'Parent 2 Email is invalid', duplicateEmailCheck: 'Email is already registered', notEmail1: 'Parent 2 Email can not be same as primary email'}" :$v="$v.alternativeEmail" v-model.trim.lazy="alternativeEmail" field-name="Parent 2 Email" />
        <reg-form-input class="col-md-5" type="email" :messages="{sameAsAlternativeEmail: 'Please enter the same value again.'}" :$v="$v.alternativeEmailConfirm" v-model.trim.lazy="alternativeEmailConfirm" field-name="Confirm Email" />
        <div class="col-md-2" />
      </b-form-row>
      <b-form-row>
        <reg-form-input class="col-md-3" type="tel" :messages="{numeric: 'Please enter only numbers'}"  v-model.trim.lazy="mobilePhone" :$v="$v.mobilePhone" field-name="Mobile phone" />
        <reg-form-checkbox class="col-md-3" v-model="smartPhone" field-name="Smart phone" :disabled="editingSubscriber" />
        <reg-form-checkbox class="col-md-6" v-model="sendLoginDetailsToMobilePhone" field-name="Send login details to this number" :disabled="editingSubscriber" />
      </b-form-row>
      <b-form-row>
        <reg-form-input class="col-md-3" type="tel" :messages="{numeric: 'Please enter only numbers'}"  v-model.trim.lazy="homePhone" :$v="$v.homePhone" field-name="Telephone - home" />
        <reg-form-input class="col-md-3" type="tel" :messages="{numeric: 'Please enter only numbers'}"  v-model.trim.lazy="workPhone" :$v="$v.workPhone" field-name="Telephone - work" />
      </b-form-row>
      <b-form-row>
        <reg-form-input size="sm" class="col-md-2" :id="safeId('pc')" :messages="{ukPostcodeValid: 'Postcode is formatted incorrectly'}" v-model.trim.lazy="postcode" :$v="$v.postcode" field-name="Postcode" @focus="postcodeFocus" />
        <reg-form-input size="sm" class="col-md-4" :id="safeId('a1')" v-model.trim.lazy="address1" :$v="$v.address1" field-name="Street Address" @focus="postcodeFocus" />
        <reg-form-input size="sm" class="col-md-3" :id="safeId('a2')" v-model.trim.lazy="address2"  :$v="$v.address2" field-name="Address 2" @focus="postcodeFocus" />
        <reg-form-input size="sm" class="col-md-3" :id="safeId('c')" v-model.trim.lazy="city" :$v="$v.city" field-name="City" @focus="postcodeFocus" />
      </b-form-row>
      <b-form-row>
        <reg-form-select :disabled="lookingUpPostcode" class="col-md-6" v-model="state" :$v="$v.state" field-name="Country" label="Country" description="Country is used to determine the program information to show below">
          <option v-for="state in $store.getters.stateList" :key="state.code" :value="state.code">{{ state.name }}</option>
        </reg-form-select>
      </b-form-row>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import oldAddressAutocomplete from './mixins/oldAddressAutocomplete'
import dobParsing from './mixins/dobParsing'

import titles from '@iris/titles'

import PostcodesIO from 'postcodesio-client'

const postcodes = new PostcodesIO()

export default {
  head: {
    title: "EstiaLabs - Family Information",
  },
  mixins: [oldAddressAutocomplete, dobParsing],
  data () {
    return {
      lookingUpPostcode: false
    }
  },
  props: {
    titles: {
      type: Array,
      default: () => titles
    },
    $v: {
      type: Object,
      required: true
    },
    loqateMessages: {
      type: Object,
      required: true
    }
  },
  watch: {
    postcode: {
      async handler (value) {
        if (!this.editingSubscriber && value && this.$v.postcode.ukPostcodeValid) {
          try {
            this.lookingUpPostcode = true
            let data = await postcodes.lookup(value)
            if (data) {
              this.$store.getters.stateList.some(({ code, name }) => {
                if (name === data.country) {
                  this.state = code
                  return true
                }
              })
            }
          } catch (_e) {} finally {
            this.lookingUpPostcode = false
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    smartPhone: {
      get () {
        return !this.sendUnlockCodeAsPlainMessage
      },
      set (value) {
        this.sendUnlockCodeAsPlainMessage = !value
      }
    },
    todaysDate () {
      return this.$store.getters.moment().subtract(18, 'years').format('YYYY-MM-DD')
    },
    editingSubscriber () {
      return this.$store.state.createAccountResult !== null
    },
    ...mapFields([
      'familyInformation.firstName',
      'familyInformation.lastName',
      'familyInformation.dob',
      'familyInformation.mobilePhone',
      'familyInformation.sendUnlockCodeAsPlainMessage',
      'familyInformation.homePhone',
      'familyInformation.workPhone',
      'familyInformation.email',
      'familyInformation.emailConfirm',
      'familyInformation.alternativeEmail',
      'familyInformation.alternativeEmailConfirm',
      'address.state',
      'address.address1',
      'address.address2',
      'address.city',
      'address.postcode',
      'address.loqateResponse',
      'familyInformation.sendLoginDetailsToMobilePhone',
      'familyInformation.authorisedPersons',
      'familyInformation.title'
    ])
  }
}
</script>

<style>

</style>
