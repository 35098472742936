<template>
  <div>
    <reg-form-select v-model="courseId" :$v="$v" :fieldName="`${name} Course`">
        <option v-for="course in $store.getters.selectedCoursesLists[subject]" :key="`${course.id.courseId}:${course.id.curriculumId}`" :value="course.id">{{ course.name }}</option>
    </reg-form-select>
    <reg-form-checkbox v-model="skipIA" fieldName="Skip Initial Assessment" v-if="hasSkipIa"/>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { IrisGetters, IrisStore, CourseTypes, IChild } from '../store/types'
import { ICourseIdPair } from '../regmodel'
import { PropValidator } from 'vue/types/options'
export default Vue.extend({
  props: {
    index: {
      type: Number,
      required: true
    },
    subject: {
      type: String,
      required: true
    } as PropValidator<CourseTypes>,
    name: {
      type: String,
      required: true
    },
    $v: {
      type: Object,
      required: true
    }
  },
  computed: {
    child ():IChild {
      return (this.$store as IrisStore).state.children[this.index]
    },
    hasSkipIa ():boolean {
      return this.subject === 'maths'
    },
    skipIA: {
      get (): boolean | undefined {
        return this.subject === 'maths' && this.child.skipIA && !!this.child.skipIA[this.subject]
      },
      set (value: boolean) {
        this.$store.commit('updateField', { path: `children[${this.index}].skipIA.${this.subject}`, value })
      }
    },
    courseId: {
      get (): ICourseIdPair | null {
        return this.child.courses[this.subject]
      },
      set (value: ICourseIdPair | null) {
        this.$store.commit('updateField', { path: `children[${this.index}].courses.${this.subject}`, value })
      }
    }
  }
})
</script>
