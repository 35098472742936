<template>
  <payment-common :refund="true">
    <p class="text-muted">No receipts are generated for this refund</p>
  </payment-common>
</template>

<script lang="ts">
import paymentMixins from './mixin'
import { ManualRefund } from '@iris/store/payments/types'

export default paymentMixins<ManualRefund>().extend({
})
</script>
