<template>
  <div>
    <h2>Choose Appointment</h2>
    <b-alert variant="danger" show v-if="error">{{ error }}</b-alert>
    <b-table v-else striped :items="data" :fields="fields" :busy="loading">
      <template #cell(actions)="row">
        <b-button @click="selectPresentation(row.item)" variant="primary">Start</b-button>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <p v-if="!loading">If the appointment is not in the list above? <b-button variant="primary" @click="selectPresentation()">Skip selection</b-button></p>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  getCurrentInstance,
  computed
} from 'vue'
import { BvTableFieldArray } from 'bootstrap-vue/esm'
import moment from 'moment-timezone'
import { TIMEZONE } from './constants'
import { Appointment, useTodaysPresentationsQuery } from './queries'
import { IrisGetters } from './store/types'

export default defineComponent({
  head: {
    title: "EstiaLabs - Choose Appointment",
  },
  setup () {
    const { $store, $bvModal } = getCurrentInstance()!.proxy as Vue

    const { onResult, onError, result, loading, error } = useTodaysPresentationsQuery({
      start: ($store.getters as IrisGetters).moment().startOf('day').toISOString(),
      end: ($store.getters as IrisGetters).moment().endOf('day').toISOString()
    })

    const data = computed(() => {
      if (result.value) {
        return result.value.appointments
      }
      return []
    })

    const selectPresentation = (pres?: Appointment) => {
      return $bvModal.msgBoxConfirm(pres ? `Confirm selection of ${pres.lastName} - ${pres.postcode}?` : 'Skip selecting an appointment').then(resp => {
        if (resp) {
          return $store.commit('selectPresentation', pres)
        }
      })
    }

    return {
      selectPresentation,
      data,
      loading,
      error,
      fields: [
        {
          formatter: (v) => moment.tz(v, TIMEZONE).format('hh:mm A'),
          key: 'appointmentStartTime',
          label: 'Time'
        },
        'lastName',
        'postcode',
        {
          key: 'actions',
          label: ''
        }
      ] as BvTableFieldArray
    }
  }
})
</script>
