<template>
  <div>
    <template v-for="(item, idx) in itemsAvailableForSale">
      <b-form-row :key="`${item.id}_checkbox`">
        <reg-form-checkbox :checked="item.quantity > 0" @input="updateItem($event, idx)">
          <span v-html="item.description" />
        </reg-form-checkbox>
      </b-form-row>
      <b-form-row v-if="item.quantity > 0" :key="`${item.id}_select`">
        <reg-form-select :placeholder="false" class="col-4" label="Quantity" :value="item.quantity" @input="updateQuantity($event, idx)" :options="[1,2,3,4,5]" />
      </b-form-row>
    </template>
    <template v-if="!$v.$invalid && saleValue">
      <p>Sale Value: {{$n(saleValue,'currency')}}</p>
    </template>
    <template v-if="!$v.$invalid && extrasValue">
      <p>Extra Items Total: {{$n(extrasValue,'currency')}}</p>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, Ref, PropOptions } from 'vue'
import { IrisGetters, IrisStore } from '../store/types'
import { mapMutations } from 'vuex'
import { find, omit } from 'lodash'
import Vuelidate, { Validation } from 'vuelidate'

export default defineComponent({
  props: {
    $v: {
      type: Object,
      required: true
    } as PropOptions<Validation>
  },
  setup (props) {
    const irisStore = getCurrentInstance()!.proxy.$store as IrisStore
    const itemsAvailableForSale = computed(() => {
      return irisStore.getters.itemsAvailableForSale.map(v => {
        return {
          ...v,
          ...omit(find(irisStore.getters.otherItemsPurchased, itm => itm.id === v.id), ['description'])
        }
      })
    })
    const extrasValue = computed<number | null>(() => {
      if (itemsAvailableForSale.value.length > 0 && irisStore.getters.isSubscriptionType) {
        return irisStore.getters.totalOfOtherItemsPurchased / 100
      } else {
        return null
      }
    })
    const saleValue = computed<number | null>(() => {
      if (itemsAvailableForSale.value.length > 0 && !irisStore.getters.isSubscriptionType) {
        return irisStore.getters.saleValue
      } else {
        return null
      }
    })

    function updateItem (value: boolean, index: number) {
      irisStore.commit('setProductItem', {
        ...omit(itemsAvailableForSale.value[index], ['description']),
        quantity: value ? 1 : 0
      })
    }

    function updateQuantity (quantity: number, index: number) {
      irisStore.commit('setProductItem', {
        ...omit(itemsAvailableForSale.value[index], ['description']),
        quantity
      })
    }

    return { itemsAvailableForSale, updateItem, saleValue, updateQuantity, extrasValue }
  }
})
</script>
