<template>
  <b-card :bg-variant="bgVariant" :border-variant="borderVariant" style="margin-bottom: 1rem" :header="headerTitle">
    <b-form-group style="margin-bottom: 0" :description="description">
      <b-form-row>
        <reg-form-select class="col-md-2" v-model="title" :$v="$v.title" :options="titles" field-name="Title" />
        <reg-form-input class="col-md-5" :$v="$v.firstName" v-model.trim.lazy="firstName" field-name="First Name" />
        <reg-form-input class="col-md-5" :$v="$v.lastName" v-model.trim.lazy="lastName" field-name="Last Name" />
      </b-form-row>
      <b-form-row>
        <reg-form-input class="col-md-5" type="email"
        :messages="{loqate: loqateEmailMessage ,email: `${headerTitle} Email is invalid`}"
        :$v="$v.email" v-model.trim.lazy="email" :field-name="`${headerTitle} Email`" />
        <reg-form-input class="col-md-5" type="email" :messages="{sameAsEmail: 'Email Address Mismatch'}" :$v="$v.emailConfirm" v-model.trim.lazy="emailConfirm" field-name="Confirm Email" />
        <div class="col-md-2" />
      </b-form-row>
      <b-form-row v-if="hasMobile">
        <reg-form-input class="col-md-3" type="tel" :messages="{numeric: 'Please enter only numbers'}"  v-model.trim.lazy="mobilePhone" :$v="$v.mobilePhone" field-name="Mobile phone" />
      </b-form-row>
      <b-form-row>
        <reg-form-input class="col-md-6" :$v="$v.dob" :value="dob" :max="todaysDate" :messages="{minValue: 'Please enter a valid date', maxValue: `${headerTitle} must be over 18 years`}" @change="parseAndUpdateDOB" type="date" field-name="DOB" label="Date of Birth" />
      </b-form-row>
      <b-form-row>
        <reg-form-input size="sm" class="col-md-2" :id="safeId('pc')" :messages="{ukPostcodeValid: 'Postcode is formatted incorrectly'}" v-model.trim.lazy="postcode" :$v="$v.postcode" field-name="Postcode" />
        <reg-form-input size="sm" class="col-md-4" :id="safeId('a1')" v-model.trim.lazy="address1" :$v="$v.address1" field-name="Street Address" />
        <reg-form-input size="sm" class="col-md-3" :id="safeId('a2')" v-model.trim.lazy="address2" :$v="$v.address2" field-name="Address 2" />
        <reg-form-input size="sm" class="col-md-3" :id="safeId('c')" v-model.trim.lazy="city" :$v="$v.city" field-name="City" />
      </b-form-row>
    </b-form-group>
  </b-card>
</template>

<script>
import oldAddressAutocomplete from './mixins/oldAddressAutocomplete'
import titles from '@iris/titles'
import dobParsing from './mixins/dobParsing'

export default {
  mixins: [oldAddressAutocomplete, dobParsing],
  props: {
    titles: {
      type: Array,
      default: () => titles
    },
    $v: {
      type: Object,
      required: true
    },
    loqateEmailMessage: {
      type: String,
      required: true
    },
    storeKey: {
      type: String,
      default: 'altPayerInformation'
    },
    headerTitle: {
      type: String,
      default: 'Alternate Payer'
    },
    description: {
      type: String,
      default: 'This is the details for the person paying for the Programme'
    },
    hasMobile: {
      type: Boolean,
      default: false
    },
    bgVariant: {
      type: String,
      default: 'light'
    },
    borderVariant: {
      type: String,
      default: null
    }
  },
  computed: {
    todaysDate () {
      return this.$store.getters.moment().subtract(18, 'years').format('YYYY-MM-DD')
    },
    ...[
      'title',
      'firstName',
      'lastName',
      'dob',
      'address1',
      'address2',
      'city',
      'postcode',
      'loqateResponse',
      'email',
      'emailConfirm',
      'mobilePhone'
    ].reduce((computedFns, field) => {
      computedFns[field] = {
        get () {
          return this.$store.getters.getField(`${this.storeKey}.${field}`)
        },
        set (value) {
          this.$store.commit('updateField', { path: `${this.storeKey}.${field}`, value })
        }
      }
      return computedFns
    }, {})
  }
}
</script>

<style>

</style>
