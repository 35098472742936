import Vue, { VueConstructor } from 'vue'
import { PropValidator } from 'vue/types/options'
import { Payment, PaidByNames } from '@iris/store/payments/types'
import PaymentCommon from './PaymentCommon.vue'
import { TDepositAltPayer, IrisGetters, DropDownOption } from '@iris/store/types'
import AltPayerInformationInnerForm from '../AltPayerInformationInnerForm.vue'
import { idMixin as safeId } from 'bootstrap-vue/esm/mixins/id'

export default function<T extends Payment> ({ includeCanEdit = true }: { includeCanEdit?: boolean } = {}) {
  return Vue.extend({
    mixins: [safeId],
    components: {
      AltPayerInformationInnerForm: AltPayerInformationInnerForm as unknown as VueConstructor<Vue>, // for some reason the typings go crazy if this isn't typed like this
      PaymentCommon
    },
    model: {
      prop: 'value',
      event: 'changed'
    },
    props: {
      $v: {
        type: Object,
        required: true
      },
      depositAltPayerValidations: {
        type: Object,
        required: false
      },
      value: {
        type: Object,
        required: false
      } as PropValidator<T | null>,
      index: {
        type: Number,
        required: true
      },
      showAltPayerDepositForm: {
        type: Boolean,
        required: false,
        default: false
      },
      loqateMessages: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        internalValue: {
          type: 'cash',
          createdAt: '',
          updatedAt: '',
          amountInCents: 0,
          paidBy: 'CUSTOMER',
          ...this.value
        } as T
      }
    },
    watch: {
      depositPayerNameOptions (val: DropDownOption<PaidByNames>[]) {
        if (this.canEdit && this.canRemove && val.every(v => v.value !== this.internalValue.paidBy)) {
          this.updatePaidBy('CUSTOMER')
        }
      },
      value: {
        handler (val: T | null, oldVal: T | null) {
          // stop the handler below from emitting changed when prop is changing
          // updatedAt and createdAt are ignored and only used by the store part
          if (JSON.stringify({
            ...val,
            updatedAt: null,
            createdAt: null
          }) !== JSON.stringify({
            ...oldVal,
            updatedAt: null,
            createdAt: null
          })) {
            this.internalValue = {
              ...this.internalValue,
              ...val
            }
          }
        },
        deep: true
      },
      internalValue: {
        handler (val?: T) {
          if (JSON.stringify(val) !== JSON.stringify(this.value)) {
            this.$emit('changed', {
              ...val
            })
          }
        },
        deep: true
      }
    },
    methods: {
      updateAmount (value: string) {
        this.amount = Number(value)
      },
      moneyNumberOnly (inputNumber: number) {
        return this.$n(inputNumber, 'money')
      },
      updatePaidBy (value: PaidByNames) {
        this.internalValue.paidBy = value
      },
      convertDepositAltPayerToFullString (value: TDepositAltPayer): PaidByNames {
        if (value === false) {
          return 'CUSTOMER'
        }
        if (value === true) {
          return 'ALTPAYER'
        }
        return value
      },
      remove () {
        this.$emit('remove')
      }
    },
    computed: { // NOTE you need to manually annotate the return types for vue typings to work properly
      canRemove (): boolean {
        return !this.internalValue.finalised
      },
      canRemoveEnabled (): boolean {
        return this.canEdit
      },
      ...includeCanEdit ? {
        canEdit (): boolean {
          return !(this as any).internalValue.finalised
        }
      } : {},
      /**
       * Convert the amount to/from decimal to whole cents for object
       * @returns {number} the amount in dollars
       */
      amount: {
        get ():number {
          return this.internalValue.amountInCents / 100
        },
        set (value: number) {
          this.internalValue.amountInCents = Math.floor(value * 100)
        }
      },
      depositPayerNameOptions (): DropDownOption<PaidByNames>[] {
        return (this.$store.getters as IrisGetters).depositPayerNameOptions.map(({ text, value }):DropDownOption<PaidByNames> => ({
          text,
          value: this.convertDepositAltPayerToFullString(value)
        }))
      },
      title (): string {
        return this.$t(`payments.title.${this.internalValue.type}`).toString()
      }
    }
  })
}
