<template>
  <div>
    <h3 class="contt-title">Subscription Information</h3>
    <reg-form-radio v-if="development" buttons v-model="paymentMethod" :$v="$v.paymentMethod" field-name="Payment Method" label="Payment method" :options="paymentMethods" description="TEMP - this is just a  convenience :)" />
    <reg-form-input label="Subscription Package" :value="goodsDescription" plaintext field-name="Subscription Package" />
    <!-- Alt payer info repeated from first page (same component and data) -->
    <altPayerInformation :$v="$v.altPayerInformation" :loqate-messages="loqateMessages" />
    <!-- deposit processing & cash processing -->
    <deposit-form :$v="$v" :loqate-messages="loqateMessages" />
    <!-- settings regarding term length etc... finance application etc... -->
    <!-- Note this is basically non existant for cash sales -->
    <installment-form :$v="$v" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import AltPayerInformation from './AltPayerInformation'
import DepositForm from './DepositForm'
import InstallmentForm from './InstallmentForm'
import financeMixins from '../mixins/financeMixins'

export default {
  components: { DepositForm, InstallmentForm, AltPayerInformation },
  mixins: [financeMixins],
  props: {
    $v: {
      type: Object,
      required: true
    },
    loqateMessages: {
      type: Object,
      required: true
    }
  },
  computed: {
    development: () => process.env.NODE_ENV === 'development',
    ...mapGetters([
      'goodsDescription',
      'saleValue',
      'paymentMethods'
    ]),
    ...mapFields([
      'finance.paymentMethod'
    ])
  }
}
</script>

<style>

</style>
