import { mapState } from 'vuex'
import Vue, { ComponentOptions } from 'vue'
import { IrisState, PaymentMethods } from '@iris/store/types'

interface FinanceProps extends Vue {
  paymentMethod: PaymentMethods
}

export default {
  computed: {
    ...mapState<{
      paymentMethod: PaymentMethods
    }>({
      paymentMethod: (state: IrisState) => state.finance.paymentMethod
    })
  },
  methods: {
    pmIn (...list) {
      return list.indexOf(this.paymentMethod) !== -1
    },
    money (inputNumber) {
      return this.$n(inputNumber, 'currency')
    }
  }
} as ComponentOptions<FinanceProps>
