import Vue, { ComponentOptions } from 'vue'
import { IrisGetters, IModuleCounts } from '@iris/store/types'

export type CourseSelectionsMixinComputedType = {
  totalModuleCount: number;
  moduleCount: IModuleCounts;
}

export default {
  computed: {
    courseSelections (this: Vue): CourseSelectionsMixinComputedType {
      return {
        totalModuleCount: (this.$store.getters as IrisGetters).moduleCount.totalWithoutRevision,
        moduleCount: (this.$store.getters as IrisGetters).moduleCount
      }
    }
  }
} as ComponentOptions<Vue>
