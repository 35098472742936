<template>
  <div>
    <h5>
      <label :for="`${uid}_all`">{{ title }}</label>
      <b-form-checkbox :checked="selectAll" :disabled="disabled" :indeterminate="partSelected" @change="toggleAll" :id="`${uid}_all`">{{!hideSelections ? 'All' : '' }}</b-form-checkbox>
    </h5>
    <slot />
    <b-form-checkbox-group v-model="values" v-if="!hideSelections">
      <div class="courseGroup" v-for="(courses, groupId) of groupedData" :key="groupId">
        <h6 v-if="groupId==='10'">Year 10-11 Maths</h6>
        <b-form-checkbox v-for="course in courses" :disabled="disabled" :value="course.id" :key="`${course.id.courseId}:${course.id.curriculumId}`">{{ course.shortName }}</b-form-checkbox>
      </div>
    </b-form-checkbox-group>
  </div>
</template>
<script>

import { groupBy as _groupBy } from 'lodash'

var counter = 0

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    hideSelections: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: false,
      default () { return [] }
    },
    uid: {
      type: String,
      required: false,
      default () {
        return `course_selection_${counter++}`
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    // for outgoing
    values () {
      this.$emit('input', this.values)
    },
    // incoming updates copy to internal data
    value (newPropertyValues) {
      this.values = newPropertyValues
    }
  },
  data () {
    return {
      values: this.value
    }
  },
  computed: {
    selectAll () {
      return this.values.length === this.data.length
    },
    partSelected () {
      return this.values.length !== 0 && (this.values.length !== this.data.length)
    },
    groupedData () {
      return _groupBy(this.data, d => d.group)
    }
  },
  methods: {
    toggleAll () {
      if (!this.selectAll) {
        this.values = this.data.map(v => v.id)
      } else {
        this.values = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  h5 {
    font-weight: bold;
  }
  label[for] {
    cursor: pointer;
  }
  .course-checkbox {
    margin-right: 5px;
    label {
      margin-left: 5px;
      font-weight: normal;
    }
  }
  .courseGroup {
    margin-bottom: 5px;
  }
</style>
