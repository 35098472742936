<template>
  <div>
    <h3>Unlock account</h3>
    <b-form-row class="justify-content-center">
      <reg-form-input ref="unlockInput" @keyup.native.enter="$emit('enter')" class="col-md-10 col-lg-6" type="tel" :messages="{numeric: 'Please only enter numbers'}" :$v="$v.unlockCode" v-model.trim.lazy="unlockCode" field-name="Unlock Code" :description="`Enter the unlock code provided via text message to ${mobilePhone}`" label="Unlock code" />
    </b-form-row>
    <b-form-row class="justify-content-center">
      <div class="col-md-10 col-lg-6">
        <b-form-row>
          <div class="col-md-4"></div>
          <div class="col-md-8">
            <slot />
          </div>
        </b-form-row>
      </div>
    </b-form-row>
    <b-form-row class="justify-content-center">
      <div class="col-md-10 col-lg-6">
        <b-form-row>
          <div class="col-md-4"></div>
          <div class="col-md-8">
            <small>
              Didn't receive the code?
              <a href @click.prevent v-b-modal.resendCode>Click here to resend</a>
            </small>
          </div>
        </b-form-row>
      </div>
    </b-form-row>
    <b-modal ref="resendCode" id="resendCode" title="Resend unlock code" @shown="populateEmailMobileFields" @ok="resendUnlock">
      <template v-if="sendUnlockCodeAsPlainMessage !== 'email'">
        <p>Mobile number</p>
        <reg-form-input type="tel" placeholder="Mobile Phone" v-model.trim.lazy="modalMobilePhone" description="If the mobile number is incorrect you can fix it here and it will update the previously entered value" />
      </template>
      <template v-else>
        <p>Email</p>
        <reg-form-input type="email" placeholder="Email" v-model.trim.lazy="modalEmail" description="If the email is incorrect you can fix it here and it will update the previously entered value" />
      </template>
      <reg-form-radio v-model="sendUnlockCodeAsPlainMessage" buttons :options="[{value: true, text: 'Message'},{value: false, text: 'Link'},{value: 'email', text: 'Email'}]" label="Send unlock code" description="If you are unable to see the unlock code or do not have a smart phone, use either Message or Email options." />
    </b-modal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  props: {
    $v: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      modalMobilePhone: null,
      modalEmail: null
    }
  },
  computed: {
    ...mapFields([
      'unlockCode',
      'familyInformation.mobilePhone',
      'familyInformation.email',
      'familyInformation.emailConfirm',
      'familyInformation.sendUnlockCodeAsPlainMessage'
    ])
  },
  mounted () {
    this.$refs.unlockInput.focus()
  },
  methods: {
    populateEmailMobileFields () {
      this.modalMobilePhone = this.mobilePhone
      this.modalEmail = this.email
    },
    resendUnlock (evt) {
      if (this.sendUnlockCodeAsPlainMessage === 'email') {
        // email
        let oldEmail = this.email
        this.email = this.modalEmail
        this.emailConfirm = this.modalEmail
        if (this.$v.email.$invalid) {
          // put back the value validation failed
          this.email = oldEmail
          this.emailConfirm = oldEmail
          evt.preventDefault()
          alert('Please enter a valid email address')
          return
        }
      } else {
        // mobile phone
        let oldMobilePhone = this.mobilePhone
        this.mobilePhone = this.modalMobilePhone
        if (this.$v.mobilePhone.$invalid) {
          // put back the value validation failed
          this.mobilePhone = oldMobilePhone
          evt.preventDefault()
          alert('Please enter a valid mobile number')
          return
        }
      }
      return this.$store.dispatch('resendUnlockCode')
    }
  }
}
</script>
