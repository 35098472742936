<template>
  <div>
    <template v-if="$store.getters.finalSaleType === 'LTL'">
      <h3>Other paperwork info</h3>
      <reg-form-textarea rows="3" :$v="$v.selfDeclaration" description="Statement to explain how you can afford to pay for the purchase" v-model.lazy="selfDeclaration" field-name="Self Declaration" label="Affordability Statement" />
      <reg-form-textarea rows="3" :$v="$v.complianceComments" description="Any other comments arising from the conversation with the Customer (e.g. ref affordability):" v-model.lazy="complianceComments" field-name="Compliance Form" label="Compliance form comments" />
    </template>
    <template v-if="['APS', 'LTL'].indexOf(paymentMethod) > -1">
      <h4>Identification</h4>
      <reg-form-checkbox :$v="$v.proofID" v-model="proofID" field-name="Have you obtained Proof of Identification" />
      <reg-form-checkbox v-if="paymentMethod === 'LTL' && decision === 'AWC'" :$v="$v.proofIncome" v-model="proofIncome" field-name="Have you obtained Proof of Income" />
    </template>
    <template v-if="$store.getters.hasDirectDebit && !$store.state.finance.recurlyDDPaymentMethodId">
      <h3 v-if="!$store.getters.isSubscriptionPlanDec2021">Direct debit details</h3>
      <reg-form-radio v-model="ddAltPayer" label="Direct debit account owner"
        :options="directDebitPayerNameOptions" description="This is the owner of the account the direct debit is coming from" />
      <alt-payer-information-inner-form :loqate-email-message="loqateMessages.ddPayerEmail" v-if="ddAltPayer === DIRECT_DEBIT_ALT_PAYER" :description="`Details of the person who's account is being used for Direct Debits for the ${$store.getters.shortCompanyName} Programme`" header-title="Direct Debit Payer" store-key="finance.dd.ddAltPayerInfo" :$v="$v.dd.ddAltPayerInfo" />
      <reg-form-input :$v="$v.dd.accountHolder" v-model.trim.lazy="accountHolder" label="Account Holder's Name" />
      <b-form-group label="Sort Code" label-cols="4" breakpoint="md">
        <b-input-group>
          <masked-input placeholder="Sort Code*" class="form-control" @blur.native="$v.dd.sortCode.$touch"
            :class="[$v.dd.sortCode.$dirty ? ($v.dd.sortCode.$error ? 'is-invalid' : 'is-valid' ) : null]"
            v-model.lazy="sortCode" mask="11-11-11" />
          <b-form-invalid-feedback v-if="!$v.dd.sortCode.required">Sort code is required</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.dd.sortCode.sortCode">Sort code is invalid</b-form-invalid-feedback>
        </b-input-group>
      </b-form-group>
      <reg-form-input :$v="$v.dd.accountNumber" type="tel" :messages="{numeric: 'Account number can only contain numbers'}" v-model.trim.lazy="accountNumber" label="Account Number" />
      <reg-form-input :$v="$v.dd.bankName" v-model.trim.lazy="bankName" label="Bank Name" />
      <reg-form-input :$v="$v.dd.bankAddress" v-model.trim.lazy="bankAddress" label="Bank Address" />
      <reg-form-checkbox v-if="$store.state.recurlyDirectDebit" :$v="$v.dd.confirmation" v-model="confirmation" field-name="I confirm that I am the account holder and I am authorised to set up Direct Debit payments on this account" />
    </template>
    <stripe-subscription v-if="isStripeDirectDebit" :$v="$v.instalments" />
  </div>
</template>

<script>
import { DIRECT_DEBIT_ALT_PAYER } from '@iris/constants'
import { mapFields } from 'vuex-map-fields'
import { mapState, mapGetters } from 'vuex'

import MaskedInput from 'vue-masked-input'
import AltPayerInformationInnerForm from './AltPayerInformationInnerForm'
import StripeSubscription from './instalments/StripeSubscription'

export default {
  components: {
    MaskedInput,
    AltPayerInformationInnerForm,
    StripeSubscription
  },
  props: {
    $v: {
      type: Object,
      required: true
    },
    $loqate: {
      type: Object,
      required: true
    },
    loqateMessages: {
      type: Object,
      required: true
    }
  },
  beforeCreate () {
    this.DIRECT_DEBIT_ALT_PAYER = DIRECT_DEBIT_ALT_PAYER
  },
  watch: {
    async sortCode (sortCode) {
      if (!sortCode.match(/\d{2}-\d{2}-\d{2}/)) return
      try {
        let response = await this.$loqate.bankBySortCode(sortCode)
        if (response) {
          this.bankName = `${response.Bank} ${response.Branch}`
          this.bankAddress = `${response.ContactAddressLine1} ${response.ContactAddressLine2} ${response.ContactPostTown} ${response.ContactPostcode}`
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
  },
  computed: {
    ...mapState({
      paymentMethod: state => state.finance.paymentMethod,
      decision: state => state.finance.decision
    }),
    ...mapGetters([
      'directDebitPayerNameOptions',
      'isStripeDirectDebit'
    ]),
    ddAltPayer: {
      get () {
        return this.$store.getters.altPayerDirectDebit
      },
      set (value) {
        this.$store.commit('updateField', { path: 'finance.dd.ddAltPayer', value })
      }
    },
    ...mapFields([
      'finance.selfDeclaration',
      'finance.complianceComments',
      'finance.proofID',
      'finance.proofIncome',
      'finance.dd.accountHolder',
      'finance.dd.bankName',
      'finance.dd.bankAddress',
      'finance.dd.sortCode',
      'finance.dd.accountNumber',
      'finance.dd.confirmation'
    ])
  }
}
</script>

<style>

</style>
