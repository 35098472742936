<template>
  <div>
    <b-card class="upload-card" title="Uploading Documents">
      <template v-if="allUploaded">
        <p>Documents uploaded successfully</p>
      </template>
      <b-progress v-else :value="Number(documentUploadSummary.totalPercent || 0)" show-progress animated />
    </b-card>
    <b-card class="upload-card" v-for="document in documentsWithUploadError" :key="document.id" :title="`Error uploading ${document.label}`">
        <p>Error uploading Reason: {{ document.uploadError }}</p>
        <b-button variant="primary" @click="retry(document)">Retry uploading {{ document.label }}</b-button>
    </b-card>
  </div>
</template>

<script>

export default {
  props: {
    $v: {
      type: Object,
      required: true
    },
    pdfBlobPromises: {
      type: Object,
      required: true
    }
  },
  computed: {
    documents () {
      return this.$store.getters.documents
    },
    documentsWithUploadError () {
      return this.documents.filter(document => document.uploadError)
    },
    documentUploadSummary () {
      return this.$store.getters.documentUploadSummary
    },
    allUploaded () {
      return this.documentUploadSummary.total > 0 && this.documentUploadSummary.uploadedCount === this.documentUploadSummary.total
    },
    anyError () {
      return !!this.documentUploadSummary.errorCount
    }
  },
  methods: {
    retry (document) {
      this.$store.dispatch('uploadDocument', { index: document.index, blobPromise: this.pdfBlobPromises[document.id] })
    }
  }
}
</script>

<style>
  .upload-card {
    margin-bottom: 1rem;
  }
</style>
