import moment from 'moment'
import Vue, { ComponentOptions } from 'vue'

interface DobOptions extends Vue {
  dob: string | null
}
/**
 * This expects a dob property on the mixed in component
 * Used in 3 places at the moment. Hence pulled out to a mixin
 */
export default {
  methods: {
    parseAndUpdateDOB (dobString: string) {
      if (dobString) {
        let date = moment(dobString, ['YYYY-MM-DD', 'D/M/YYYY'])
        if (date.isValid()) this.dob = date.format('YYYY-MM-DD')
      } else {
        this.dob = null
      }
    }
  }
} as ComponentOptions<DobOptions>
